@import "@fontsource/open-sans/400.css";
@import "@fontsource/open-sans/600.css";
@import "@fontsource/open-sans/700.css";
@import "@fontsource/open-sans/800.css";

@tailwind base;
@tailwind components;

.minutes:after,
.legend:after {
  @apply w-4 h-4 bg-no-repeat inline-block bg-contain align-text-top;
  content: " ";
}

.estimated .minutes:after,
.estimated.legend:after {
  background-image: url("assets/rss.svg");
}

.text-highlight {
  @apply text-lg tracking-tight leading-tight;
}

.number-highlight {
  @apply text-2xl font-sans font-extrabold whitespace-nowrap;
}

@screen md {
  .number-highlight {
    @apply text-3xl;
  }
}

@screen lg {
  .text-highlight {
    @apply text-xl;
  }
  .number-highlight {
    @apply text-4xl;
  }

  .minutes:after {
    @apply w-6 h-6;
  }

  .legend:after {
    @apply w-4 h-4;
  }
}

@screen xl {
  .text-highlight {
    @apply text-2xl;
  }

  .number-highlight {
    @apply text-5xl;
  }

  .minutes:after {
    @apply w-8 h-8;
  }

  .legend:after {
    @apply w-6 h-6;
  }
}

@screen 2xl {
  .text-highlight {
    @apply text-3xl;
  }

  .number-highlight {
    @apply text-6xl;
  }

  .minutes:after {
    @apply w-12 h-12;
  }

  .legend:after {
    @apply w-8 h-8;
  }
}

@screen portrait-sm {
  .number-highlight {
    @apply text-5xl;
  }

  .minutes:after {
    @apply w-6 h-6;
  }
  .legend:after {
    @apply w-4 h-4;
  }
}

@screen portrait-md {
  .text-highlight {
    @apply text-4xl;
  }

  .number-highlight {
    @apply text-6xl;
  }

  .minutes:after {
    @apply w-8 h-8;
  }
  .legend:after {
    @apply w-6 h-6;
  }
}

@screen portrait-xl {
  .text-highlight {
    @apply text-5xl;
  }

  .number-highlight {
    @apply text-7xl;
  }

  .minutes:after {
    @apply w-12 h-12;
  }
  .legend:after {
    @apply w-8 h-8;
  }
}

@tailwind utilities;
